const sanityConfig = {
  projectId: process.env.NEXT_PUBLIC_SANITY_PROJECT_ID!,
  dataset: process.env.NEXT_PUBLIC_SANITY_DATASET!,
};

interface GetImageUrlProps {
  src: string;
  width: number;
  quality: number;
}

export default function getImageUrl({ src, width, quality }: GetImageUrlProps) {
  const ref = src?.split('-');
  const ext = ref.pop();
  ref.shift();
  const refStr = ref.join('-');

  return `https://cdn.sanity.io/images/${sanityConfig.projectId}/${sanityConfig.dataset}/${refStr}.${ext}?w=${width}&q=${quality}&fit=crop&auto=format`;
}
