export const COMPANY_NAME = 'NOMI';
export const COMPANY_DESCRIPTION =
  'NOMI is a furniture brand that offers customisable furniture for your home.';
export const DOMAIN = 'https://www.nomi.com.au';
export const LEAD_TIME = '12-14 weeks';
export const CUSTOMISE_SEARCH_PARAM = 'customise';

const SANITY_ID = process.env.NEXT_PUBLIC_SANITY_PROJECT_ID;

export const CORS = {
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Methods': 'GET, OPTIONS',
  'Access-Control-Allow-Headers': 'Origin, X-Requested-With, Content-Type, Accept',
  'Access-Control-Max-Age': '86400',
};

export const PREFETCH_DOMAINS = [
  'https://cdn.sanity.io',
  `https://${SANITY_ID}.apicdn.sanity.io`,
  `https://${SANITY_ID}.api.sanity.io`,
  'https://viewer.shapediver.com',
  'https://sdeuc1.eu-central-1.shapediver.com',
  'https://www.google-analytics.com',
  'https://www.googletagmanager.com',
  'https://www.googleapis.com',
];

export const PROFILE_LINKS = [
  {
    label: 'Your Profile',
    href: '/profile',
  },
  {
    label: 'Your Quotes',
    href: '/quotes',
  },
  {
    label: 'Your Orders',
    href: '/orders',
  },
  {
    label: 'Your Cart',
    href: '/cart',
  },
];

export const COUNTRIES = [
  { value: 'AU', label: 'Australia' },
  { value: 'SG', label: 'Singapore' },
  { value: 'US', label: 'United States' },
  { value: 'UK', label: 'United Kingdom' },
];

export const ALLOWED_COUNTRIES = ['AU'];

export const TAX_RATE = 1.1;

export const QUANTITY_MIN = 1;
export const QUANTITY_MAX = 500;
