import getImageUrl from '@/utils/getImageUrl';

interface ImageLoaderProps {
  src: string;
  width?: number;
  quality?: number;
}

export default function imageLoader({ src, width = 48, quality = 75 }: ImageLoaderProps) {
  if (!src) return '';

  if (src.startsWith('./') || src.startsWith('/')) return src;

  if (src.includes('https')) return `${src}?w=${width}&q=${quality}&fit=crop&auto=format`;

  return getImageUrl({ src, width, quality });
}
